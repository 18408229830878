<template>
  <div class="risk">
    <div class="risk-title">
      <h4>数智云智能风控系统</h4>
      <p>
        运用大数据，人工智能和深度学习等技术，通过对海量数据结构化清洗与特征提取，
        创建出经过实践检验的风控模型，实现风控自动化的同时，能够有效降低金融企业的风险损失。
      </p>
    </div>
    <div class="risk-main">
      <div class="main-one">
        <div class="one-title">
          <div class="title"></div>
        </div>
        <div class="one-text">
          <img src="../../assets/pic/-s-pic_product fkxt_cpjg.svg" alt="" />
        </div>
      </div>
      <div class="main-two">
        <div class="two-title"></div>
        <div class="two-text">
          <div class="text-top">
            将人工智能技术应用于信贷风险控制，结合结构化和非结构化数据，可以覆盖过去金融业务忽视的长尾人群，
            精准的完成业务流程中风险的识别、控制和监测。同时，人工智能的模型迭代相对传统模型表现出高度自动化的特征。
          </div>
          <div class="text-center">
            <div class="text-title">
              <span>主要人工智能技术</span>
            </div>
            <div class="text-value">
              <div
                class="text-card"
                v-for="(item, index) in cardList"
                :key="index"
              >
                <div class="card-imd">
                  <img :src="item.url" alt="" />
                </div>
                <h4>{{ item.title }}</h4>
                <span v-for="(list, index) in item.list" :key="index">{{
                  list
                }}</span>
              </div>
            </div>
          </div>
          <div class="text-bottom"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardList: [
        {
          url: require("../../assets/pic/service_card_1.png"),
          title: "生物特征识别",
          list: [
            "指纹识别",
            "人脸识别",
            "声纹识别",
            "虹膜识别",
            "静脉识别",
            "......",
          ],
        },
        {
          url: require("../../assets/pic/service_card_2.png"),
          title: "机器学习",
          list: ["强化学习", "监督式学习", "非监督式学习", "......"],
        },
        {
          url: require("../../assets/pic/service_card_3.png"),
          title: "自然语言处理",
          list: [
            "语音识别",
            "文字识别",
            "语义识别",
            "智能问答",
            "信息抽取",
            "......",
          ],
        },
        {
          url: require("../../assets/pic/service_card_4.png"),
          title: "计算机视觉",
          list: ["图像分类", "对象检测", "语义分割", "实例分割", "......"],
        },
        {
          url: require("../../assets/pic/service_card_5.png"),
          title: "知识图谱",
          list: ["信息抽取", "知识表示", "知识融合", "知识推理", "......"],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.risk {
  width: 100%;
  margin: 30px auto 0;
  .risk-title {
    width: 1000px;
    margin: 0 auto 30px;
    box-sizing: border-box;
    padding: 30px;
    background: url("../../assets/pic/service_title_bg.png") center center no-repeat;
    box-shadow: 0px 0px 6px rgba(3, 1, 32, 0.2);
    h4 {
      font-size: 24px;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
      color: #ffffff;
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #2173ff;
        position: absolute;
        top: 8px;
        left: -15px;
      }
    }
  }
  .risk-main {
    width: 100%;
    padding-bottom: 60px;
    .main-one {
      width: 100%;
      box-sizing: border-box;
      padding: 30px 0;
      background-color: #021428;
      .one-title {
        width: 1000px;
        height: 60px;
        margin: 0 auto;
        .title {
          width: 176px;
          height: 62px;
          margin: auto;
          background: url("../../assets/pic/cpjg.svg") no-repeat;
          background-size: 100%;
        }
      }
      .one-text {
        width: 1000px;
        height: 596px;
        margin: auto;
        margin-top: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .main-two {
      width: 1000px;
      margin: auto;
      padding-top: 30px;
      .two-title {
        width: 177px;
        height: 62px;
        margin: auto;
        background: url("../../assets/pic/cpjj.svg") no-repeat;
        background-size: 100%;
      }
      .two-text {
        width: 100%;
        margin-top: 30px;
        .text-top {
          width: 100%;
          color: #ffffff;
          font-size: 14px;
          line-height: 30px;
          text-indent:2em
        }
        .text-center {
          width: 100%;
          padding: 30px 20px;
          box-sizing: border-box;
          margin-top: 30px;
          background-color: #0A2E58;
          box-shadow: 0px 0px 6px rgba(3, 1, 32, 0.2);
          .text-title {
            text-align: center;
            font-size: 22px;
            margin-bottom: 20px;
            color: #ffffff;
            line-height: 24px;
          }
          .text-value {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            .text-card {
              width: 176px;
              height: 330px;
              padding: 30px 20px;
              box-sizing: border-box;
              text-align: center;
              background-color: #082546;
              color: #ffffff;
              .card-img {
                width: 60px;
                height: 60px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              h4 {
                font-size: 18px;
                margin: 15px 0;
                font-weight: 500;
                line-height: 30px;
              }
              span {
                display: block;
                font-size: 14px;
                line-height: 25px;
              }
            }
          }
        }
        .text-bottom {
          width: 1000px;
          height: 484px;
          margin: 30px auto 0;
          background: url("../../assets/pic/-s-pic_product fkxt_fxjcxt.svg") no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
</style>