<template>
  <div class="assistance">
    <div class="assistance-title">
      <h4>数智云助贷业务管理平台</h4>
      <div>
        <span>
          数智云助贷业务管理平台，是一套完善的汽车金融服务IT解决方案，它支持金融产品资金投放，资产进件，
          风险审核，风险管控，租金回收，还款追踪，违约预警，资产处置等功能与一体的金融服务平台。
        </span>
        <span>
          面向资金方，它提供金融业务项目材料的征集，收集，项目跟进，项目追踪，租金回收追踪，法务审核，复审，租后管理，
          不良资产处置，租后检查，风险防范，风险评估，资金申请，资金投放，资金明细，财务结清，资产处置等功能。
        </span>
        <span>
          面向渠道方，它支持网络下单与门店下单，集用户认证，用户进件，用户信审，风控建模，风控审核，商品管理，商品下单，
          首付款支付，车辆锁定，自动报价，资产管理，月供跟踪，违约监控等一体化购车服务平台。
        </span>
        <span>
          面向C端用户，它支持用户通过小程序或者APP查询，筛选汽车金融产品，支持定金预付，试乘试驾，邀约到店等功能。</span
        >
      </div>
    </div>
    <div class="assistance-main">
      <div class="main-one">
        <div class="one-title">
          <div class="title"></div>
        </div>
        <div class="one-text">
          <img src="../../assets/pic/-s-pic_product zdyw_cpjg.svg" alt="" />
        </div>
      </div>
      <div class="main-two">
        <div class="two-title">
          <img src="../../assets/pic/jsts.svg" alt="" />
          <span
            >平台采用Spring
            Cloud/Dubbo为核心的微服务架构。支持亿级网关设计和弹性扩容，支持大规模海量用户设计，
            支持不间断服务能力的双活体系设计，支持高度可扩展性。</span
          >
        </div>
        <div class="two-text">
          <img src="../../assets/pic/-s-pic_product zdyw_jsts.svg" alt="" />
        </div>
      </div>
      <div class="main-three">
        <div class="three-tip">
          <div class="left-img">
            <img src="../../assets/pic/word.svg" alt="" />
          </div>
          <div class="right-text">
            <div class="right-title">
              <img src="../../assets/pic/fwts.svg" alt="" />
            </div>
            <div class="right-msg">
              数智云控团队不仅仅输出IT产品，同样也输出汽车金融产品的设计，代维，代管等服务与咨询，支持类PPP/BOT等各种模式的合作。
            </div>
            <div class="right-item" v-for="(item, index) in list" :key="index">
              <div class="item-l">{{ item.label }}</div>
              <div class="item-r">{{ item.value }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-four">
        <div class="four-title">
          <img src="../../assets/pic/itjcywlc.svg" alt="" />
        </div>
        <div class="four-text">
          <div class="four-textOne">
            <div class="img">贷前阶段</div>
            <div class="textOne-top">
              <div class="top-info">
                <h4>资金方</h4>
                <div
                  class="item-info"
                  v-for="(item, index) in fundSide"
                  :key="index"
                >
                  <img :src="item.url" alt="" />
                  <span>{{ item.text }}</span>
                </div>
              </div>
              <div class="top-info">
                <h4>渠道商（资产端含融资租赁资产运营企业）</h4>
                <div
                  class="item-info"
                  v-for="(rightItem, rightIndex) in business"
                  :key="rightIndex"
                >
                  <img :src="rightItem.url" alt="" />
                  <span>{{ rightItem.text }}</span>
                </div>
              </div>
            </div>
            <div class="textOne-bottom">
              <h4>移动端消费</h4>
              <div class="item-b">
                <img
                  src="../../assets/pic/icon_product zdyw_circle.png"
                  alt=""
                />
                <span
                  >金融产品选择，下单，支付首付，视频面签，填报个人信息等</span
                >
              </div>
            </div>
          </div>
          <div class="four-textTwo">
            <div class="img">贷中阶段</div>
            <div class="textTwo">
              <div class="itemTwo" v-for="(item, index) in loanIn" :key="index">
                <img :src="item.url" alt="" />
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
          <div class="four-textThree">
            <div class="img">贷后阶段</div>
            <div class="textThree">
              <div class="three-info">
                <h4>材料管理</h4>
                <div>
                  <img
                    src="../../assets/pic/icon_product zdyw_circle.png"
                    alt=""
                  />
                  <span>材料邮寄，材料归档</span>
                </div>
              </div>
              <div class="three-info">
                <h4>还款管理</h4>
                <div>
                  <img
                    src="../../assets/pic/icon_product zdyw_circle.png"
                    alt=""
                  />
                  <span>还款计划表，提前结清，预期管理</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-five">
        <div class="five-title">
          <img src="../../assets/pic/itsslc.svg" alt="" />
        </div>
        <div class="five-text">
          <div class="img">
            <img src="../../assets/pic/-s-pic_product zdyw_NO 01.svg" alt="" />
          </div>
          <div class="icon">
            <img src="../../assets/pic/icon_product zdyw_triangle.png" alt="" />
          </div>
          <div class="img">
            <img src="../../assets/pic/-s-pic_product zdyw_NO 02.svg" alt="" />
          </div>
          <div class="icon">
            <img src="../../assets/pic/icon_product zdyw_triangle.png" alt="" />
          </div>
          <div class="img">
            <img src="../../assets/pic/-s-pic_product zdyw_NO 03.svg" alt="" />
          </div>
          <div class="icon">
            <img src="../../assets/pic/icon_product zdyw_triangle.png" alt="" />
          </div>
          <div class="img">
            <img src="../../assets/pic/-s-pic_product zdyw_NO 04.svg" alt="" />
          </div>
          <div class="img">
            <img src="../../assets/pic/-s-pic_product zdyw_NO 05.svg" alt="" />
          </div>
          <div class="icon">
            <img src="../../assets/pic/icon_product zdyw_triangle.png" alt="" />
          </div>
          <div class="img">
            <img src="../../assets/pic/-s-pic_product zdyw_NO 06.svg" alt="" />
          </div>
          <div class="icon">
            <img src="../../assets/pic/icon_product zdyw_triangle.png" alt="" />
          </div>
          <div class="img">
            <img src="../../assets/pic/-s-pic_product zdyw_NO 07.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          label: "团队高精尖：",
          value: "团队由汽车金融行业10多年从业人员与10多年软件研发人员构成。",
        },
        {
          label: "产品专业化：",
          value: "基于成熟业务模式，成功案例基础上的产品供给。",
        },
        {
          label: "高度配置化：",
          value: "系统支持功能表单，流程和接口可配置化。",
        },
        {
          label: "收费多样化：",
          value: "按单计算/按年计算/一次性采购/服务分成。",
        },
        {
          label: "个性定制化：",
          value: "根据客户特定需求提供定制化开发。",
        },
        {
          label: "多平台操作：",
          value: "支持支付宝，微信，钉钉等轻量应用小程序。",
        },
        {
          label: "贷后服务：",
          value: "提供贷后资产管理等特殊功能。",
        },
      ],
      fundSide: [
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "1.渠道管理  代理商、分公司。经销商，合作商，融资租赁企业",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "2.移动端提报 进件提报 合同签约",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "3.客户预审 大数据前置查询 面签 关系网络分析 助贷征信预审",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "4.进件提报 信息录入 金融产品选择 贷款信息展示	支付",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "5.合同签约 法大大在线签约",
        },
      ],
      business: [
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "1.进销存管理 车辆出入库 车辆调拨 GPS派单等",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "2.移动端提报	进件提报 合同签约",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "3.客户预审 大数据前置查询 面签 关系网络分析 助贷征信预审",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "4.进件提报 信息录入 金融产品选择 贷款信息展示 支付",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "5.合同签约 法大大在线签约",
        },
      ],
      loanIn: [
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "在售车辆  车，牌，证等管理",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "GPS派单 GPS安装",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "抵押管理 车辆抵押提醒",
        },
        {
          url: require("../../assets/pic/icon_product zdyw_circle.png"),
          text: "人员风险预警 贷中大数据筛查",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.assistance {
  width: 100%;
  margin: 30px auto 0;
  .assistance-title {
    width: 1000px;
    height: 313px;
    box-sizing: border-box;
    padding: 30px;
    margin: 0 auto 30px;
    color: #ffffff;
    background: url("../../assets/pic/service_title_bg2.png") no-repeat;
    background-size: auto;
    h4 {
      font-size: 24px;
      line-height: 24px;
    }
    div {
      margin-top: 20px;
      span {
        display: block;
        font-size: 14px;
        margin-bottom: 20px;
        position: relative;
        &::before {
          display: block;
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          left: -15px;
          top: 8px;
          background: #2173ff;
          border-radius: 50%;
        }
      }
    }
  }
  .assistance-main {
    width: 100%;
    .main-one {
      width: 100%;
      padding: 30px 0;
      background-color: #021428;
      .one-title {
        width: 1200px;
        height: 60px;
        margin: auto;
        .title {
          width: 177px;
          height: 60px;
          margin: auto;
          background: url("../../assets/pic/cpjg.svg") no-repeat;
          background-size: 100%;
        }
      }
      .one-text {
        width: 1000px;
        height: auto;
        margin: 30px auto 0;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .main-two {
      width: 1000px;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .two-title {
        width: 440px;
        box-sizing: border-box;
        padding: 56px 0;
        img {
          width: 177px;
          height: 62px;
        }
        span {
          display: block;
          font-size: 14px;
          line-height: 30px;
          color: #ffffff;
          text-indent: 2em;
        }
      }
      .two-text {
        width: 400px;
        height: auto;
        margin-left: 160px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .main-three {
      width: 100%;
      padding: 30px 0;
      background-color: #021428;
      .three-tip {
        width: 1000px;
        margin: auto;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-img {
          width: 470px;
          height: auto;
          background: url('../../assets/pic/bgImg.svg') 0 0 no-repeat;
          background-size: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
        .right-text {
          width: 472px;
          color: #ffffff;
          box-sizing: border-box;
          padding: 30px 0;
          .right-title {
            width: 172px;
            height: 62px;
            margin-bottom: 15px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .right-msg {
            font-size: 14px;
            margin-bottom: 30px;
            line-height: 20px;
            color: #ffffff;
            text-indent: 2em;
          }
          .right-item {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            &::before {
              display: block;
              content: "";
              width: 6px;
              height: 6px;
              background: #398df0;
              border-radius: 50%;
              position: absolute;
              top: 5px;
              left: -15px;
            }
          }
        }
      }
    }
    .main-four {
      width: 1000px;
      margin: 30px auto;
      .four-title {
        width: 317px;
        height: auto;
        margin: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .four-text {
        width: 100%;
        margin-top: 30px;
        .four-textOne {
          width: 100%;
          box-sizing: border-box;
          padding: 76px 40px 20px;
          position: relative;
          background-color: #0a2e58;
          border-radius: 4px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
          .img {
            width: 160px;
            height: 56px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            padding: 12px 32px;
            font-size: 24px;
            color: #f6f7fa;
            line-height: 27px;
            background-color: #398df0;
            border-radius: 4px 0px 20px 0px;
          }
          .textOne-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .top-info {
              width: 50%;
              h4 {
                font-size: 18px;
                margin-bottom: 16px;
                color: #ffffff;
                line-height: 24px;
              }
              .item-info {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 25px;
                position: relative;
                &:last-child {
                  margin-bottom: 0;
                }
                &::before {
                  display: block;
                  content: "";
                  width: 2px;
                  height: 25px;
                  box-sizing: border-box;
                  background: #398df0;
                  position: absolute;
                  left: 8px;
                  top: 24px;
                }
                &:last-child::before {
                  display: none;
                }
                img {
                  width: 18px;
                  height: 18px;
                  margin-right: 10px;
                }
                span {
                  font-size: 14px;
                  color: #ffffff;
                  line-height: 24px;
                }
              }
            }
          }
          .textOne-bottom {
            width: 100%;
            margin-top: 30px;
            h4 {
              font-size: 18px;
              margin-bottom: 15px;
              color: #ffffff;
              line-height: 24px;
            }
            .item-b {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }
              span {
                font-size: 14px;
                color: #ffffff;
                line-height: 24px;
              }
            }
          }
        }
        .four-textTwo {
          width: 100%;
          margin-top: 20px;
          box-sizing: border-box;
          padding: 75px 35px 0;
          position: relative;
          background: #0a2e58;
          border-radius: 4px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
          .img {
            width: 160px;
            height: 56px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            padding: 12px 32px;
            font-size: 24px;
            color: #f6f7fa;
            line-height: 27px;
            background-color: #398df0;
            border-radius: 4px 0px 20px 0px;
          }
          .textTwo {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .itemTwo {
              width: 50%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 20px;
              img {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }
              span {
                color: #ffffff;
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
        .four-textThree {
          width: 100%;
          margin-top: 20px;
          box-sizing: border-box;
          padding: 75px 40px 20px;
          position: relative;
          background-color: #0a2e58;
          border-radius: 4px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
          .img {
            width: 160px;
            height: 56px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            padding: 12px 32px;
            font-size: 24px;
            color: #f6f7fa;
            line-height: 27px;
            background-color: #398df0;
            border-radius: 4px 0px 20px 0px;
          }
          .textThree {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .three-info {
              width: 50%;
              h4 {
                font-size: 18px;
                color: #ffffff;
                line-height: 24px;
                margin-bottom: 20px;
              }
              div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                  width: 18px;
                  height: 18px;
                }
                span {
                  font-size: 14px;
                  margin-left: 10px;
                  color: #ffffff;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
    .main-five {
      width: 100%;
      padding: 30px 0 0;
      background-color: #021428;
      .five-title {
        width: 229px;
        height: auto;
        margin: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .five-text {
        width: 1000px;
        margin: 30px auto 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .img {
          width: 225px;
          height: auto;
          margin-bottom: 30px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .icon {
          width: 16px;
          height: 16px;
          margin: 0 8px;
          margin-bottom: 30px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
</style>