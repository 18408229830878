<template>
  <div class="bg" :key="flag">
    <div class="top-banner">
      <div class="top-text">
        <div class="text-info">
          <div class="info-title">产品服务</div>
          <div class="info-content">
            公司通过产品，输出独特的技术与服务，为传统金融机构，融资租赁企业，汽车资产运营企业提供业务，风控，运营等多种场景的科技解决方案。
            让科技服务渗透到各类消费领域的全产业链环节，与合作伙伴共享共赢数字化消费金融生态系统。
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-tab" :class="'main-tab'+flag">
        <div
          :class="item.id == flag ? 'tabs active' : 'tabs'"
          v-for="(item, index) in tabsList"
          @click="goTabs(item.id)"
          :key="index"
        >
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="main-info">
        <RiskManage v-if="flag == '1'"></RiskManage>
        <FareManage v-if="flag == '2'"></FareManage>
        <AssistanceManage v-if="flag == '3'"></AssistanceManage>
      </div>
    </div>
  </div>
</template>

<script>
import RiskManage from "../components/Server/riskManage.vue";
import FareManage from "../components/Server/fareManage.vue";
import AssistanceManage from "../components/Server/assistanceManage.vue";
export default {
  name: "Server",
  data() {
    return {
      flag: "1",
      tabsList: [
        {
          name: "数智云智能风控系统",
          id: 1,
        },
        {
          name: "数智云车资产管理系统",
          id: 2,
        },
        {
          name: "数智云助贷业务管理平台",
          id: 3,
        },
      ],
    };
  },
  components: {
    RiskManage,
    FareManage,
    AssistanceManage,
  },
  watch: {
    $route: "getFlag",
  },
  created() {
    window.scrollTo(0, 0);
    if (this.$route.query.flag) {
      this.flag = this.$route.query.flag;
    }
  },
  methods: {
    goTabs(e) {
      this.flag = e;
    },
    getFlag() {
      if (this.$route.query.flag) {
        window.scrollTo(0, 0);
        this.flag = this.$route.query.flag;
      } else {
        this.flag = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #061c36;
  .top-banner {
    height: 395px;
    width: 100%;
    background: url("../assets/pic/service_top banner.png") center center no-repeat;
    .top-text {
      width: 1000px;
      margin: 0 auto;
      padding-top: 140px;
      .text-info {
        width: 470px;
        color: #ffffff;
        .info-title {
          font-size: 36px;
          font-weight: 400;
          line-height: 58px;
          margin-bottom: 24px;
        }
        .info-content {
          font-size: 14px;
          line-height: 28px;
          text-indent:2em
        }
      }
    }
  }
  .main {
    padding-top: 35px;
    width: 100%;
    .main-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      flex-grow: 1;
      width: 1000px;
      height: 135px;
      margin: auto;
      .tabs {
        padding: 45px 30px;
        box-sizing: border-box;
        height: 100%;
        width: 400px;
        span {
          font-size: 20px;
          color: #bcf2ff;
          position: relative;
        }
        span::before {
          display: block;
          content: "";
          width: 53px;
          height: 4px;
          background-color: transparent;
          position: absolute;
          top: 40px;
          left: 0;
        }
      }
      .active {
        span {
          color: #ffffff;
          font-weight: bolder;
        }
        span::before {
          background-color: #ffffff;
        }
      }
    }
    .main-tab1 {
      background: url("../assets/pic/active1.png") no-repeat;
      background-size: 100% 100%;
    }
    .main-tab2 {
      background: url("../assets/pic/active2.png") no-repeat;
      background-size: 100% 100%;
    }
    .main-tab3 {
      background: url("../assets/pic/active3.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>