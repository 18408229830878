var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assistance"},[_vm._m(0),_c('div',{staticClass:"assistance-main"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"main-three"},[_c('div',{staticClass:"three-tip"},[_vm._m(3),_c('div',{staticClass:"right-text"},[_vm._m(4),_c('div',{staticClass:"right-msg"},[_vm._v(" 数智云控团队不仅仅输出IT产品，同样也输出汽车金融产品的设计，代维，代管等服务与咨询，支持类PPP/BOT等各种模式的合作。 ")]),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"right-item"},[_c('div',{staticClass:"item-l"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"item-r"},[_vm._v(_vm._s(item.value))])])})],2)])]),_c('div',{staticClass:"main-four"},[_vm._m(5),_c('div',{staticClass:"four-text"},[_c('div',{staticClass:"four-textOne"},[_c('div',{staticClass:"img"},[_vm._v("贷前阶段")]),_c('div',{staticClass:"textOne-top"},[_c('div',{staticClass:"top-info"},[_c('h4',[_vm._v("资金方")]),_vm._l((_vm.fundSide),function(item,index){return _c('div',{key:index,staticClass:"item-info"},[_c('img',{attrs:{"src":item.url,"alt":""}}),_c('span',[_vm._v(_vm._s(item.text))])])})],2),_c('div',{staticClass:"top-info"},[_c('h4',[_vm._v("渠道商（资产端含融资租赁资产运营企业）")]),_vm._l((_vm.business),function(rightItem,rightIndex){return _c('div',{key:rightIndex,staticClass:"item-info"},[_c('img',{attrs:{"src":rightItem.url,"alt":""}}),_c('span',[_vm._v(_vm._s(rightItem.text))])])})],2)]),_vm._m(6)]),_c('div',{staticClass:"four-textTwo"},[_c('div',{staticClass:"img"},[_vm._v("贷中阶段")]),_c('div',{staticClass:"textTwo"},_vm._l((_vm.loanIn),function(item,index){return _c('div',{key:index,staticClass:"itemTwo"},[_c('img',{attrs:{"src":item.url,"alt":""}}),_c('span',[_vm._v(_vm._s(item.text))])])}),0)]),_vm._m(7)])]),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assistance-title"},[_c('h4',[_vm._v("数智云助贷业务管理平台")]),_c('div',[_c('span',[_vm._v(" 数智云助贷业务管理平台，是一套完善的汽车金融服务IT解决方案，它支持金融产品资金投放，资产进件， 风险审核，风险管控，租金回收，还款追踪，违约预警，资产处置等功能与一体的金融服务平台。 ")]),_c('span',[_vm._v(" 面向资金方，它提供金融业务项目材料的征集，收集，项目跟进，项目追踪，租金回收追踪，法务审核，复审，租后管理， 不良资产处置，租后检查，风险防范，风险评估，资金申请，资金投放，资金明细，财务结清，资产处置等功能。 ")]),_c('span',[_vm._v(" 面向渠道方，它支持网络下单与门店下单，集用户认证，用户进件，用户信审，风控建模，风控审核，商品管理，商品下单， 首付款支付，车辆锁定，自动报价，资产管理，月供跟踪，违约监控等一体化购车服务平台。 ")]),_c('span',[_vm._v(" 面向C端用户，它支持用户通过小程序或者APP查询，筛选汽车金融产品，支持定金预付，试乘试驾，邀约到店等功能。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-one"},[_c('div',{staticClass:"one-title"},[_c('div',{staticClass:"title"})]),_c('div',{staticClass:"one-text"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_cpjg.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-two"},[_c('div',{staticClass:"two-title"},[_c('img',{attrs:{"src":require("../../assets/pic/jsts.svg"),"alt":""}}),_c('span',[_vm._v("平台采用Spring Cloud/Dubbo为核心的微服务架构。支持亿级网关设计和弹性扩容，支持大规模海量用户设计， 支持不间断服务能力的双活体系设计，支持高度可扩展性。")])]),_c('div',{staticClass:"two-text"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_jsts.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-img"},[_c('img',{attrs:{"src":require("../../assets/pic/word.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-title"},[_c('img',{attrs:{"src":require("../../assets/pic/fwts.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four-title"},[_c('img',{attrs:{"src":require("../../assets/pic/itjcywlc.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textOne-bottom"},[_c('h4',[_vm._v("移动端消费")]),_c('div',{staticClass:"item-b"},[_c('img',{attrs:{"src":require("../../assets/pic/icon_product zdyw_circle.png"),"alt":""}}),_c('span',[_vm._v("金融产品选择，下单，支付首付，视频面签，填报个人信息等")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four-textThree"},[_c('div',{staticClass:"img"},[_vm._v("贷后阶段")]),_c('div',{staticClass:"textThree"},[_c('div',{staticClass:"three-info"},[_c('h4',[_vm._v("材料管理")]),_c('div',[_c('img',{attrs:{"src":require("../../assets/pic/icon_product zdyw_circle.png"),"alt":""}}),_c('span',[_vm._v("材料邮寄，材料归档")])])]),_c('div',{staticClass:"three-info"},[_c('h4',[_vm._v("还款管理")]),_c('div',[_c('img',{attrs:{"src":require("../../assets/pic/icon_product zdyw_circle.png"),"alt":""}}),_c('span',[_vm._v("还款计划表，提前结清，预期管理")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-five"},[_c('div',{staticClass:"five-title"},[_c('img',{attrs:{"src":require("../../assets/pic/itsslc.svg"),"alt":""}})]),_c('div',{staticClass:"five-text"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_NO 01.svg"),"alt":""}})]),_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../assets/pic/icon_product zdyw_triangle.png"),"alt":""}})]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_NO 02.svg"),"alt":""}})]),_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../assets/pic/icon_product zdyw_triangle.png"),"alt":""}})]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_NO 03.svg"),"alt":""}})]),_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../assets/pic/icon_product zdyw_triangle.png"),"alt":""}})]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_NO 04.svg"),"alt":""}})]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_NO 05.svg"),"alt":""}})]),_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../assets/pic/icon_product zdyw_triangle.png"),"alt":""}})]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_NO 06.svg"),"alt":""}})]),_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../assets/pic/icon_product zdyw_triangle.png"),"alt":""}})]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/pic/-s-pic_product zdyw_NO 07.svg"),"alt":""}})])])])
}]

export { render, staticRenderFns }