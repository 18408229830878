<template>
  <div class="fare">
    <div class="fare-title">
      <h4>数智云车资产管理系统</h4>
      <span
        >“采用5G+物联网+互联网”技术，实现车辆档案管理，库存管理，库存盘点，出入库管理；车辆补牌，补证，补标监控，保费提醒，
        保费缴纳，违章查询，事故查询；车辆贷后月供催收，催缴，账单划扣，费用清算；车辆理赔，推修，ETC申请等功能。
      </span>
      <span
        >优势：支持车资产全生命周期管理、支持多职能车辆手机端APP管理、支持线上操作线上结算方便快捷。</span
      >
    </div>
    <div class="fare-main">
      <div class="main-one">
        <div class="bg">
          <div class="one-title"></div>
          <div class="one-text">
            整个系统采用B/S的结构，基于SpringBoot2.x、SpringCloud并采用前后端分离的企业级微服务敏捷开发系统架构；同时引入组件化的思想实现高内聚低耦
            合并且高度可配置化。真正实现了基于RBAC、JWT和Oauth2的无状态统一权限认证的解决方案，面向互联网设计同时适合B端和C端用户，支持CI/CD多环境部署，
            并提供开放平台方便第三方系统接入，实现开放赋能；同时还集合各种微服务治理功能和监控功能。
          </div>
          <div class="one-img"></div>
        </div>
      </div>
      <div class="main-two">
        <div class="two-title">
          <img src="../../assets/pic/bfgnyl.svg" alt="" />
        </div>
        <div class="two-text">
          <img
            src="../../assets/pic/-s-pic_product service_bfgnyl.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.fare {
  width: 100%;
  margin: 30px auto 0;
  .fare-title {
    width: 1000px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 30px;
    background: url("../../assets/pic/service_title_bg.png") no-repeat;
    background-size: auto;
    box-shadow: 0px 0px 6px rgba(3, 1, 32, 0.2);
    h4 {
      font-size: 24px;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 20px;
    }
    span {
      font-size: 14px;
      margin-bottom: 20px;
      display: block;
      width: 100%;
      color: #ffffff;
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #2173ff;
        position: absolute;
        top: 8px;
        left: -15px;
      }
    }
  }
  .fare-main {
    width: 100%;
    .main-one {
      width: 100%;
      background-color: #021428;
      margin-top: 30px;
      box-sizing: border-box;
      padding: 30px 0;
      .bg {
        width: 1000px;
        margin: 0 auto;
        .one-title {
          width: 176px;
          height: 62px;
          margin: auto;
          background: url("../../assets/pic/xtjg.svg") no-repeat;
          background-size: 100%;
        }
        .one-text {
          width: 100%;
          font-size: 14px;
          margin: 30px 0;
          line-height: 30px;
          text-indent: 2em;
          color: #ffffff;
        }
        .one-img {
          width: 100%;
          height: 624px;
          background: url("../../assets/pic/-s-pic_product service_xtjg.svg")
            no-repeat;
          background-size: 100%;
        }
      }
    }
    .main-two {
      width: 1000px;
      margin: auto;
      padding-top: 60px;
      position: relative;
      .two-title {
        width: 264px;
        height: 62px;
        position: absolute;
        top: 30px;
        left: 368px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .two-text {
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>